// import React from "react";

// const Notifications = () => {
//   const notifications = [
//     {
//       title: 'Your release "Starlight" just got approved!',
//       details: "Details about the notification",
//       bgColor: "bg-lime-500",
//     },
//     {
//       title: "Royalties for the quarter are here!",
//       details: "Details about the notification",
//       bgColor: "bg-zinc-400",
//     },
//     {
//       title: "Your invoice has been generated",
//       details: "Details about the notification",
//       bgColor: "bg-zinc-400",
//     },
//     {
//       title: 'Your release "Flow" got rejected',
//       details: "Details about the notification",
//       bgColor: "bg-red-600",
//     },
//   ];

//   return (
//     <section className="flex flex-col justify-center text-lg text-white max-w-[584px]">
//       <div className="flex flex-col px-6 pt-1 pb-5 w-full bg-black rounded-lg max-md:px-5 max-md:max-w-full bg-[#252525]">
//         {notifications.map((item, index) => (
//           <div
//             key={index}
//             className={`flex flex-col w-full items-start py-2 mt-4 ${item.bgColor} bg-opacity-0 max-md:pr-5 max-md:max-w-full`}
//           >
//             <p>{item.title}</p>
//             <p>{item.details}</p>
//           </div>
//         ))}
//         <div className="flex justify-end">
//           <button className="bg-[#FF6C2F] text-black w-24 mt-6 rounded-md h-10">
//             View All
//           </button>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Notifications;

import React from "react";
import { useNavigate } from "react-router-dom";

const Notifications = ({ notifications }) => {
  const navigate = useNavigate();
  if (!notifications || notifications.length === 0) {
    return <div>No new notifications.</div>;
  }

  const handleViewAll = () => {
    navigate("/upload");
  };

  return (
    <div
      className="px-6 pt-1 pb-5 w-full text-white rounded-lg max-md:px-5 max-md:max-w-full bg-[#252525]"
      style={{ padding: "10px", maxHeight: "584px", overflowY: "auto" }}
    >
      <ul>
        {notifications.map((notification, index) => (
          <li
            key={index}
            style={{ padding: "10px 10px", borderBottom: "1px solid #ddd" }}
            className="flex flex-col w-full items-start py-2 ${item.bgColor} bg-opacity-0 max-md:pr-5 max-md:max-w-full"
          >
            {notification}
          </li>
        ))}
      </ul>
      <div className="flex justify-end">
        <button
          className="bg-[#FF6C2F] text-black w-24 mt-6 rounded-md h-10"
          onClick={handleViewAll}
        >
          View All
        </button>
      </div>
    </div>
  );
};

export default Notifications;

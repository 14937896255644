import React from "react";
import { useState, useEffect } from "react";
import { publicRouteLink } from "../constant/Routes";
import TermsCheckbox from "./TermsCheckbox";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MainContext } from "../context/main";
import { useContext } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

const SignUp1 = ({ onClose }) => {
  const [ph, setPh] = useState("");
  const [isValidEmail, setValidEmail] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isPhoneExists, setIsPhoneExists] = useState(false);
  const { formData, handleFormData } = useContext(MainContext);
  const {
    countryCode,
    setCountryCode,
    mob,
    setMob,
    buttonName,
    buttonValue,
    updateButtonInfo,
  } = useContext(MainContext);
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    console.log("FormData Updated:", formData);
  }, [formData]);

  // Function to send OTP after phone verification
  const sendOTP = async () => {
    onClose && onClose();
    updateButtonInfo("Send OTP", "SignUp");

    console.log("Mob:", mob, "Country Code:", countryCode);
    try {
      if (!formData?.phone) {
        toast.error("Please enter your number");
        return;
      }
      const data = {
        mob: mob,
        countryCode: "+" + countryCode,
      };
      formData.phone = data.mob;
      formData.country_code = data.countryCode;

      const resp = await fetch(
        "https://haartistaloud-backend.kollywoodhungama.com/v2/send-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!resp.ok) {
        const errorData = await resp
          .json()
          .catch(() => ({ error: "Invalid response format" }));
        console.error(
          "Failed to send OTP:",
          resp.status,
          resp.statusText,
          errorData
        );
        toast.error("OTP not sent. Please try again!");
        return;
      }

      const respData = await resp.json();
      console.log("Response from send OTP API:", respData);

      if (respData.result.statusCode === 200) {
        toast.success("OTP sent successfully!");
        navigate(publicRouteLink.OTP_VERIFICATION);
      } else {
        toast.error("OTP not sent. Please try again!");
      }
    } catch (error) {
      console.error("Send OTP Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  // Function to check if the phone number exists
  const checkPhoneNumber = async () => {
    if (!isChecked) {
      toast.error("Please agree to the Terms & Conditions.");
      return;
  }
    if (!mob) {
      toast.error("Please enter a valid phone number.");
      return;
    }

    try {
      const response = await fetch(
        `https://haartistaloud-backend.kollywoodhungama.com/v1/CheckPhoneNo/${mob}`
      );

      if (!response.ok) {
        const errorDetails = await response.text();
        console.error("Phone check error:", errorDetails);
        toast.error("Phone number check failed. Please try again.");
        return;
      }

      const data = await response.json();
      if (data.status === 200) {
        sendOTP();
      } else if (data.status === 409) {
        toast.error("Phone number already exists. Please log in.");
      } else {
        toast.error("Unexpected phone check response. Try again.");
      }
    } catch (error) {
      console.error("Check Phone Error:", error);
      toast.error("Error checking phone number. Try again later.");
    }
  };

  // Google login and profile fetch handling
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setUser(tokenResponse);
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v1/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        setProfile(res.data);
        navigate("/dashboard");
      } catch (error) {
        console.error("Google Login Error:", error);
        toast.error("Failed to fetch Google profile.");
      }
    },
    onError: (error) => {
      console.error("Login Failed:", error);
      toast.error("Google login failed. Please try again.");
    },
  });

  return (
    <div className="flex items-center justify-center bg-[#1A1A1A] px-16 py-12">
      <form className="bg-[#1A1A1A] text-white rounded-mdF w-96">
        <h1 className="text-3xl font-bold text-center">Create your account</h1>
        <p className="mt-6">
          Create an account now and be the next sensation in the enterainment
          industry!
        </p>

        <div className="pt-3">
          <label
            className="block text-white text-sm font-normal"
            htmlFor="name"
          >
            Full Name
            <span className="text-[#FF6C2F]">*</span>
          </label>
          <input
            name="fullName"
            className="w-full outline-none bg-white text-black rounded-md pl-2 h-12"
            type="text"
            id="name"
            value={formData?.fullName}
            onChange={handleFormData}
            required
          />

          <label
            className="block text-white text-sm font-normal pt-4"
            htmlFor="ph"
          >
            Mobile Number
            <span className="text-[#FF6C2F]">*</span>
          </label>
          <PhoneInput
            inputProps={{
              name: "phone",
              required: true,
            }}
            inputClass="w-full"
            country={"in"}
            value={ph}
            required
            onChange={(phone, country, e) => {
              let newPh = phone.split(country.dialCode);
              phone = newPh[1];
              setMob(phone);
              setCountryCode(country.dialCode);
              handleFormData(e);
            }}
            className="text-black"
          />

          <label
            className="block text-white text-sm font-normal pt-4"
            htmlFor="email"
          >
            Email Address
            <span className="text-[#FF6C2F]">*</span>
          </label>
          <input
            name="email"
            className={`w-full outline-none bg-white text-black rounded-md pl-2 h-12
                         ${isValidEmail} ? '' : 'border-red-500' `}
            type="email"
            id="email"
            value={formData?.email}
            onChange={handleFormData}
            required
          />
          {!isValidEmail && (
            <p className="text-red-500 text-sm mt-1">
              Please enter a valid email address.
            </p>
          )}
          <TermsCheckbox isChecked={isChecked} setIsChecked={setIsChecked} />

          <button
            className="w-full bg-[#FF6C2F] text-white h-12 rounded-md text-sm font-medium mt-3"
            id="sign-in-button"
            type="button"
            onClick={checkPhoneNumber}
            // onClick={sendOTP}
          >
            Get Started
          </button>

          {isPhoneExists && (
            <p className="text-[#FA3235]">
              The user account already exists; please sign in.
            </p>
          )}

          <div className="my-1 text-center">or</div>
          <button
            className="w-full bg-[#262626] text-white h-12 rounded-md text-sm font-medium mt-3"
            type="button"
            onClick={handleGoogleLogin}
          >
            <span className="flex flex-row justify-center items-center">
              <img
                src="assets\google-icon.svg"
                alt="google-icon"
                className="pr-2"
              />
              Sign in with Google
            </span>
          </button>

          <div className="flex flex-row justify-between items-center mt-5">
            <p className="items-start">Already have an account?</p>
            <a
              className="bg-[#262626] text-[#FF6C2F] w-28 h-9 flex items-center justify-center rounded-md"
              href={publicRouteLink.SIGNIN}
            >
              Login Now
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignUp1;

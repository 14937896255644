import React from "react";
import { useContent } from "../context/main.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Uploads() {
  const { setContentType } = useContent();
  const navigate = useNavigate();

  const handleAudioClick = () => {
    setContentType("audio");
    navigate("/details-audio"); // Navigate to DetailsAudio page
  };

  const handleVideoClick = () => {
    setContentType("video");
    navigate("/details-audio"); // Navigate to DetailsVideo page
  };
  return (
    <>
      <div
        className=""
        style={{
          backgroundImage: "linear-gradient(to left, #0c0c0c, #131212);",
        }}
      >
        <div>
          <h2
            className="text-[#FFFFFF] text-[1.6rem] ml-[13.5%] mt-[3%] mb-[1%]"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create
          </h2>
        </div>
        <div className="upload-box flex items-center justify-center">
          <div className="w-[1000px] h-[500px] bg-[#ffffff0d] rounded-md flex flex-col items-center justify-center mb-[7%]">
            <div className="text-center mb-8">
              <h2
                className="text-[#FFFFFF] text-[1.1rem] flex justify-center items-center"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Choose what you want to{" "}
                <span className="text-[#FF6C2F] ml-1">distribute</span>
              </h2>
            </div>
            <div className="button-box-upload flex justify-center">
              <button
                className="w-[11rem] h-[8rem] bg-[#302F2F] rounded-md text-[#FFFFFF] mx-[20px] hover:bg-[#FF6C2F] active:bg-[#c75525]"
                onClick={handleAudioClick}
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                <Link to="/details-audio">Audio</Link>
              </button>
              <button
                className="w-[11rem] h-[8rem] bg-[#302F2F] rounded-md text-[#FFFFFF] mx-[20px] hover:bg-[#FF6C2F] active:bg-[#c75525]"
                onClick={handleVideoClick}
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                <Link to="/details-audio">Video</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

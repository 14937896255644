import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useContent } from "../context/main.js";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiUpload } from "react-icons/fi";
import "@fortawesome/fontawesome-free/css/all.min.css";

export default function Invoice() {
  const [showForm, setShowForm] = useState(false);
  const [showInvoiceForm, setShowInvoiceForm] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showBankDetailsForm, setShowBankDetailsForm] = useState(false);
  const [showAttachmentForm, setShowAttachmentForm] = useState(false);
  const navigate = useNavigate();
  const [signatureImage, setSignatureImage] = useState(null);
  const [cgstSelection, setCgstSelection] = useState(null);
  const [msmedSelection, setMsmedSelection] = useState(null);

  const handleYesClick = (setter) => {
    setter("yes");
  };

  const handleNoClick = (setter) => {
    setter("no");
  };

  const handleNextClick = () => {
    if (cgstSelection === "yes" || cgstSelection === "no") {
      if (msmedSelection === "yes" || msmedSelection === "no") {
        setShowForm(true);
      } else {
        toast.error(`Please select "yes" or "no" to proceed.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } else {
      toast.error(`Please select "yes" or "no" to proceed.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  const [uploadedFiles, setUploadedFiles] = useState({
    pan: false,
    cheque: false,
    gst: false,
    msme: false,
  });

  const handleUpload = (fileKey) => (event) => {
    if (event.target.files.length > 0) {
      setUploadedFiles((prevState) => ({ ...prevState, [fileKey]: true }));
    }
  };

  return (
    <div>
      <div className="flex">
        <h2
          className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          Royalty <span className="text-[#FF6C2F] ml-1">Report</span>
        </h2>
      </div>
      <div
        className="container1"
        style={{ width: "auto", minHeight: "700px", height: "auto" }}
      >
        <div className=" pl-[5%] pt-[4%] profileBar w-[28%] bg-[#ffffff0d] rounded-md ">
          <div className="flex flex-col items-start h-[70%]">
            <button className="text-[#FFFFFF] text-[1.3rem]">
              <Link to="/royalty-report">Generate Report</Link>
            </button>
            <br />
            <button className="text-[#FF6C2F] text-[1.3rem]">
              <Link to="/">Invoice</Link>
            </button>
          </div>
        </div>
        <div className="formIprs flex flex-col mt-[5%] w-[70%]">
          {showForm ? (
            <div className="flex flex-col items-center text-[#FFFFFF] gap-3">
              <div className="bg-[#303030] w-[80%] rounded-md  border-[0.5px] border-[#FF6C2F]">
                <div className=" w-[100%] flex justify-between items-center p-[5%]">
                  <h2>Invoice Details</h2>
                  <button
                    className="text-[#FF6C2F]"
                    onClick={() => setShowInvoiceForm(!showInvoiceForm)}
                  >
                    {showInvoiceForm ? (
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="size-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="size-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    )}
                  </button>
                </div>
                {showInvoiceForm && (
                  <div className="flex flex-col pl-[5%] pr-[5%] pb-[5%]">
                    <div className=" flex flex-col ">
                      <label htmlFor="">
                        Vendor's Name<span className="text-[#FF6C2F]">*</span>
                      </label>
                      <input
                        type="text"
                        className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                      />
                    </div>

                    <div className="flex justify-between mt-[3%]">
                      <div className="flex flex-col">
                        <label htmlFor="" className="">
                          Invoice Number
                        </label>
                        <input
                          type="text"
                          className="rounded-[5px] bg-[#4c4c4c] w-[13rem] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                          // value={cityValue}
                          // onChange={handleCityChange}
                          // required
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="" className="text-[#FFFFFF]">
                          Invoice Date
                        </label>
                        <input
                          type="date"
                          className="rounded-[5px] bg-[#4c4c4c] w-[13rem] h-[2.7rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem] pr-5 opacity-80"
                          // value={stateValue}
                          // onChange={handleStateChange}
                          // required
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="bg-[#303030] w-[80%] rounded-md  border-[0.5px] border-[#FF6C2F]">
                <div className=" w-[100%] flex justify-between items-center p-[5%]">
                  <h2>Address Details</h2>
                  <button
                    className="text-[#FF6C2F]"
                    onClick={() => setShowAddressForm(!showAddressForm)}
                  >
                    {showAddressForm ? (
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="size-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="size-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    )}
                  </button>
                </div>
                {showAddressForm && (
                  <div className="flex flex-col pl-[5%] pr-[5%] pb-[5%]">
                    <div className=" flex flex-col ">
                      <label htmlFor="">
                        Address<span className="text-[#FF6C2F]">*</span>
                      </label>
                      <input
                        type="text"
                        className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                      />
                    </div>

                    <div className="flex justify-between mt-[3%]">
                      <div className="flex flex-col">
                        <label htmlFor="" className="">
                          Pin Code
                        </label>
                        <input
                          type="text"
                          className="rounded-[5px] bg-[#4c4c4c] w-[13rem] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                          // value={cityValue}
                          // onChange={handleCityChange}
                          // required
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="" className="text-[#FFFFFF]">
                          City
                        </label>
                        <input
                          type="text"
                          className="rounded-[5px] bg-[#4c4c4c] w-[13rem] h-[2.7rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem] pr-5 opacity-80"
                          // value={stateValue}
                          // onChange={handleStateChange}
                          // required
                        />
                      </div>
                    </div>
                    <div className="flex justify-between mt-[3%]">
                      <div className="flex flex-col">
                        <label htmlFor="" className="">
                          State
                        </label>
                        <select
                          name="state"
                          id="state"
                          className="pl-3 rounded-[5px] bg-[#4c4c4c] w-[13rem] h-[2.7rem] text-[#FFFFFF] font-[Poppins] outline-none"
                        >
                          <option value="">Select</option>

                          {/* States and Union Territories */}
                          <option value="Andaman and Nicobar Islands">
                            Andaman and Nicobar Islands
                          </option>
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Dadra and Nagar Haveli and Daman and Diu">
                            Dadra and Nagar Haveli and Daman and Diu
                          </option>
                          <option value="Delhi">Delhi</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jammu and Kashmir">
                            Jammu and Kashmir
                          </option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Ladakh">Ladakh</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Puducherry">Puducherry</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                        </select>
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="" className="text-[#FFFFFF]">
                          Landmark(Optional)
                        </label>
                        <input
                          type="text"
                          className="rounded-[5px] bg-[#4c4c4c] w-[13rem] h-[2.7rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem] pr-5 opacity-80"
                          // value={stateValue}
                          // onChange={handleStateChange}
                          // required
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="bg-[#303030] w-[80%] rounded-md  border-[0.5px] border-[#FF6C2F]">
                <div className=" w-[100%] flex justify-between items-center p-[5%]">
                  <h2>Payment Details<span className="text-[#FF6C2F]">*</span></h2>
                  <button
                    className="text-[#FF6C2F]"
                    onClick={() => setShowPaymentForm(!showPaymentForm)}
                  >
                    {showPaymentForm ? (
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="size-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="size-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    )}
                  </button>
                </div>
                {showPaymentForm && (
                  <div className="flex flex-col pl-[5%] pr-[5%] pb-[5%] gap-3">
                    {cgstSelection !== "no" && (
                      <div className=" flex flex-col ">
                        <label htmlFor="">GSTIN Number</label>
                        <input
                          type="text"
                          className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                        />
                      </div>
                    )}
                    {cgstSelection !== "no" && (
                      <div className=" flex flex-col ">
                        <label htmlFor="">Place of Supply</label>
                        <input
                          type="text"
                          className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                        />
                      </div>
                    )}
                    <div className=" flex flex-col ">
                      <label htmlFor="">CIN Number</label>
                      <input
                        type="text"
                        className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                      />
                    </div>
                    {cgstSelection !== "no" && (
                      <div className=" flex flex-col ">
                        <label htmlFor="">
                          Service Accounting Number (SAC)
                        </label>
                        <input
                          type="text"
                          className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                        />
                      </div>
                    )}
                    <div className=" flex flex-col ">
                      <label htmlFor="">PAN Number</label>
                      <input
                        type="text"
                        className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                      />
                    </div>
                    {cgstSelection !== "no" && (
                      <div className=" flex flex-col ">
                        <label htmlFor="">Taxable Value</label>
                        <input
                          type="text"
                          className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                        />
                      </div>
                    )}
                    {cgstSelection !== "no" && (
                      <div className=" flex flex-col ">
                        <label htmlFor="">CGST Amount</label>
                        <input
                          type="text"
                          className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                        />
                      </div>
                    )}
                    {cgstSelection !== "no" && (
                      <div className=" flex flex-col ">
                        <label htmlFor="">SGST Amount</label>
                        <input
                          type="text"
                          className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                        />
                      </div>
                    )}
                    {cgstSelection !== "no" && (
                      <div className=" flex flex-col ">
                        <label htmlFor="">IGST Amount</label>
                        <input
                          type="text"
                          className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                        />
                      </div>
                    )}
                    <div className=" flex flex-col ">
                      <label htmlFor="">Total Amount (in numbers)</label>
                      <input
                        type="text"
                        className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                      />
                    </div>
                    <div className=" flex flex-col ">
                      <label htmlFor="">Total Amount (in words)</label>
                      <input
                        type="text"
                        className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="bg-[#303030] w-[80%] rounded-md  border-[0.5px] border-[#FF6C2F]">
                <div className=" w-[100%] flex justify-between items-center p-[5%]">
                  <h2>Bank Details<span className="text-[#FF6C2F]">*</span></h2>
                  <button
                    className="text-[#FF6C2F]"
                    onClick={() => setShowBankDetailsForm(!showBankDetailsForm)}
                  >
                    {showBankDetailsForm ? (
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="size-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="size-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    )}
                  </button>
                </div>
                {showBankDetailsForm && (
                  <div className="flex flex-col pl-[5%] pr-[5%] pb-[5%]">
                    <div className=" flex flex-col">
                      <label htmlFor="">Name of the Bank</label>
                      <input
                        type="text"
                        className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                      />
                    </div>
                    <div className=" flex flex-col mt-[3%]">
                      <label htmlFor="">Beneficiary Name</label>
                      <input
                        type="text"
                        className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                      />
                    </div>
                    <div className=" flex flex-col mt-[3%]">
                      <label htmlFor="">IFSC</label>
                      <input
                        type="text"
                        className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                      />
                    </div>
                    <div className=" flex flex-col mt-[3%]">
                      <label htmlFor="">Account Number</label>
                      <input
                        type="text"
                        className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                      />
                    </div>
                    <div className=" flex flex-col mt-[3%]">
                      <label htmlFor="">Confirm Account Number</label>
                      <input
                        type="text"
                        className="rounded-[5px] bg-[#4c4c4c] w-[] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                      />
                    </div>

                    <div className="flex justify-between mt-[3%]">
                      <div className="flex flex-col">
                        <label htmlFor="" className="">
                          Branch
                        </label>
                        <input
                          type="text"
                          className="rounded-[5px] bg-[#4c4c4c] w-[13rem] h-[2.7rem] text-[#FFFFFF] font-[Poppins]"
                          // value={cityValue}
                          // onChange={handleCityChange}
                          // required
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="" className="text-[#FFFFFF]">
                          Account Type
                        </label>
                        <select
                          name="state"
                          id="state"
                          className="pl-3 rounded-[5px] bg-[#4c4c4c] w-[13rem] h-[2.7rem] text-[#FFFFFF] font-[Poppins] outline-none"
                        >
                          {" "}
                          <option value="Savings">Savings Account</option>
                          <option value="Current">Current Account</option>
                          <option value="Salary">Salary Account</option>
                          <option value="NRI">NRI Account</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="bg-[#303030] w-[80%] rounded-md  border-[0.5px] border-[#FF6C2F]">
                <div className=" w-[100%] flex justify-between items-center p-[5%]">
                  <h2>Attachments<span className="text-[#FF6C2F]">*</span></h2>
                  <button
                    className="text-[#FF6C2F]"
                    onClick={() => setShowAttachmentForm(!showAttachmentForm)}
                  >
                    {showAttachmentForm ? (
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="size-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="size-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    )}
                  </button>
                </div>
                {showAttachmentForm && (
                  <div className="flex flex-col pl-[5%] pr-[5%] pb-[5%]">
                    <div className="font-[Poppins] text-[#FFFFFF] text-[0.9rem] w-full mt-5">
                      {/* <!-- First Row: PAN & Cancelled Cheque --> */}
                      <div className="flex justify-between items-center w-full mb-2">
                        <label for="upload_file1" className="font-[Poppins]">
                          Upload copy of PAN
                        </label>
                        <label for="upload_file2" className="font-[Poppins]">
                          Upload copy of Cancelled Cheque
                        </label>
                      </div>

                      <div className="flex items-center w-full">
                        {/* <!-- Upload PAN --> */}
                        <div className="w-[18rem] flex items-center">
                          <div className="w-[7rem]">
                            <input
                              type="file"
                              id="upload_file1"
                              accept=".jpeg,.jpg,.pdf"
                              hidden
                              onChange={handleUpload("pan")}
                            />
                            <label
                              for="upload_file1"
                              className="flex items-center bg-[#4c4c4c] rounded-md w-full px-4 py-2 text-[#e2e2e2] cursor-pointer"
                            >
                              <span className="flex-grow">Upload</span>
                              <FiUpload className="text-[#FF6C2F]" />
                            </label>
                          </div>
                          <div className="w-[2rem] pl-2">
                            {uploadedFiles.pan && (
                              <i
                                className="fa fa-check-circle text-green-500"
                                aria-hidden="true"
                              ></i>
                            )}
                          </div>
                        </div>

                        {/* <!-- Upload Cancelled Cheque --> */}
                        <div className="flex items-center">
                          <div className="w-[7rem]">
                            <input
                              type="file"
                              id="upload_file2"
                              accept=".jpeg,.jpg,.pdf"
                              hidden
                              onChange={handleUpload("cheque")}
                            />
                            <label
                              for="upload_file2"
                              className="flex items-center bg-[#4c4c4c] rounded-md w-full px-4 py-2 text-[#e2e2e2] cursor-pointer"
                            >
                              <span className="flex-grow">Upload</span>
                              <FiUpload className="text-[#FF6C2F]" />
                            </label>
                          </div>
                          <div className="w-[2rem] pl-2">
                            {uploadedFiles.cheque && (
                              <i
                                className="fa fa-check-circle text-green-500"
                                aria-hidden="true"
                              ></i>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* <!-- Second Row: GST & MSME Certificate --> */}
                      <div className="flex items-center w-full mt-7 mb-2 gap-12">
                        {cgstSelection === "yes" && (
                          <label for="upload_file3" className="font-[Poppins]">
                            Upload copy of GST Certificate
                          </label>
                        )}
                        {msmedSelection === "yes" && (
                          <label for="upload_file4" className="font-[Poppins]">
                            Upload copy of MSME Certificate
                          </label>
                        )}
                      </div>

                      <div className="flex items-center w-full">
                        {/* <!-- Upload GST Certificate --> */}
                        {cgstSelection === "yes" && (
                          <div className="w-[18rem] flex items-center">
                            <div className="w-[7rem]">
                              <input
                                type="file"
                                id="upload_file3"
                                accept=".jpeg,.jpg,.pdf"
                                hidden
                                onChange={handleUpload("gst")}
                              />
                              <label
                                for="upload_file3"
                                className="flex items-center bg-[#4c4c4c] rounded-md w-full px-4 py-2 text-[#e2e2e2] cursor-pointer"
                              >
                                <span className="flex-grow">Upload</span>
                                <FiUpload className="text-[#FF6C2F]" />
                              </label>
                            </div>
                            <div className="w-[2rem] pl-2">
                              {uploadedFiles.gst && (
                                <i
                                  className="fa fa-check-circle text-green-500"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>
                          </div>
                        )}

                        {/* <!-- Upload MSME Certificate --> */}
                        {msmedSelection === "yes" && (
                          <div className="flex items-center">
                            <div className="w-[7rem]">
                              <input
                                type="file"
                                id="upload_file4"
                                accept=".jpeg,.jpg,.pdf"
                                hidden
                                onChange={handleUpload("msme")}
                              />
                              <label
                                for="upload_file4"
                                className="flex items-center bg-[#4c4c4c] rounded-md w-full px-4 py-2 text-[#e2e2e2] cursor-pointer"
                              >
                                <span className="flex-grow">Upload</span>
                                <FiUpload className="text-[#FF6C2F]" />
                              </label>
                            </div>
                            <div className="w-[2rem] pl-2">
                              {uploadedFiles.msme && (
                                <i
                                  className="fa fa-check-circle text-green-500"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      {/* <!-- Error Message Placeholder --> */}
                      {/* <span className="block mt-2 text-red-500 text-sm">
                        Error: File upload failed.
                      </span> */}
                      <div className="flex flex-col items-center mt-7">
                        <h3 className="font-[Poppins] text-[1rem] text-[#FFFFFF] mb-1">
                          Authorized Signature
                        </h3>
                        <div className="w-[20rem] h-[10rem] border border-dashed border-[#FFFFFF] rounded-md flex justify-center items-center bg-[#333333]">
                          {signatureImage ? (
                            <img
                              // src={signatureImage}
                              alt="Authorized Signature"
                              className="max-w-full max-h-full object-contain"
                            />
                          ) : (
                            <p className="text-[#AAAAAA] text-[0.9rem]">
                              Signature will be displayed here
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div style={{ flexGrow: "8" }}>
              {/* CGST Section */}
              <div className="pl-[10%] mb-[8%]">
                <h2
                  className="text-[#FFFFFF] text-[1rem] mb-[3%]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Are you registered under the CGST 2017?
                </h2>
                <div className="flex justify-start mt-[1%]">
                  <button
                    className={`w-[6rem] h-[3rem] rounded-md text-[#FFFFFF] ${
                      cgstSelection === "yes" ? "bg-[#FF6C2F]" : "bg-[#302F2F]"
                    } hover:bg-[#FF6C2F] active:bg-[#c75525]`}
                    onClick={() => handleYesClick(setCgstSelection)}
                  >
                    Yes
                  </button>
                  <button
                    className={`w-[6rem] h-[3rem] rounded-md text-[#FFFFFF] mx-[25px] ${
                      cgstSelection === "no" ? "bg-[#484848]" : "bg-[#302F2F]"
                    } hover:bg-[#484848] active:bg-[#484848]`}
                    onClick={() => handleNoClick(setCgstSelection)}
                  >
                    No
                  </button>
                </div>
              </div>

              {/* MSMED Section */}
              <div className="pl-[10%] w-[80%]">
                <h2
                  className="text-[#FFFFFF] text-[1rem] mb-[3%]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Are you registered under the Micro, Small and Medium
                  Enterprises Development, 2006 (MSMED)?
                </h2>
                <div className="flex justify-start mt-[1%]">
                  <button
                    className={`w-[6rem] h-[3rem] rounded-md text-[#FFFFFF] ${
                      msmedSelection === "yes" ? "bg-[#FF6C2F]" : "bg-[#302F2F]"
                    } hover:bg-[#FF6C2F] active:bg-[#c75525]`}
                    onClick={() => handleYesClick(setMsmedSelection)}
                  >
                    Yes
                  </button>
                  <button
                    className={`w-[6rem] h-[3rem] rounded-md text-[#FFFFFF] mx-[25px] ${
                      msmedSelection === "no" ? "bg-[#484848]" : "bg-[#302F2F]"
                    } hover:bg-[#484848] active:bg-[#484848]`}
                    onClick={() => handleNoClick(setMsmedSelection)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-end items-end mb-[5%] mt-[5%] pr-[5%]">
            {!showForm ? (
              <div>
                <button
                  className="text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
                  style={{
                    fontFamily: "Poppins, sanSerif",
                  }}
                  onClick={handleNextClick}
                >
                  Next
                </button>
              </div>
            ) : (
              <div>
                <button
                  className="text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122] mb-[1%]"
                  style={{
                    fontFamily: "Poppins, sanSerif",
                  }}
                  onClick={handleNextClick}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

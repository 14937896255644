import React, { useState, useEffect } from "react";
import {toast, ToastContainer} from "react-toastify";
function ProfilePicture(props) {
  const [image, setImage] = useState();
  const [imageError, setImageError] = useState("");

  useEffect(() => {
    //get api integration
    const savedImage = localStorage.getItem("uploadedImage");
    if (savedImage) {
      setImage(savedImage);
    }
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB limit
      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
  
      if (!validTypes.includes(file.type)) {
        setImageError("Only .jpeg, .jpg, and .png files are allowed.");
        return; 
      }
  
      if (file.size > MAX_FILE_SIZE) {
        setImageError("File size exceeds the maximum limit of 2 MB.");
        return; 
      }
  
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result;
  
        setImage(imageUrl);
        localStorage.setItem("uploadedImage", imageUrl);
        setImageError(""); 
      };
  
      reader.readAsDataURL(file);
    }
  };
  
  

  return (
    <div className="profile_bar">
      <div
        className="image-uploader"
        style={{
          width: "200px",
          height: "200px",
          marginLeft: "15%",
          marginTop: "15%",
          borderRadius: "10px",
          backgroundImage: `url(${
            image || "./assets/default-avatar-photo-icon.jpg"
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column", 
          justifyContent: "flex-end", 
          alignItems: "flex-end", 
          overflow: "hidden", 
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", 
          border: "2px solid #ff6c2f", 
        }}
      >
        {/* Hidden file input */}
        <input
          type="file"
          id="upload_ProfilePicture"
          name="file"
          accept=".jpeg,.jpg,.png"
          onChange={handleImageChange}
          hidden
        />

        {/* Upload Button */}
        <label
          htmlFor="upload_ProfilePicture"
          style={{
            backgroundColor: "#ff6c2f", 
            padding: "8px 12px", 
            borderRadius: "5px",
            cursor: "pointer", 
            display: "flex", 
            alignItems: "center",
            gap: "6px", 
            fontSize: "12px",
            margin: "10px",
          }}
        >
          <img
            src={"./assets/imgUpload.png"}
            alt="Upload Icon"
            style={{
              width: "15px",
              height: "15px",
            }}
          />
          Edit
        </label>
      </div>
      <div className="px-[15%] pb-[2%] text-[12px] h-[5%]">
        {imageError && <p className="text-red-500">{imageError}</p>}
      </div>
      <div>
        <h2 className="font-[Poppins] text-[#FFFFFF] ml-[15%] text-[130%] mt-[5%]">
          {props.artistName}
        </h2>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ProfilePicture;

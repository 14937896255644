import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const [imgError, setImgError] = useState(false);
  const handleImageError = () => {
    setImgError(true);
  };

  const handleLogOut = () =>{
    sessionStorage.removeItem('Token');
    navigate("/");
  }
  return (
    <div
      className="bg-[#252525] rounded-md shadow-sm w-64 p-4 text-white"
      style={{ marginRight: "10px" }}
    >
      {/* User Info Section */}
      <div className="flex items-center mb-4 p-2 rounded-md bg-[#FF6C2F]">
        {imgError ? (
          <ion-icon
            style={{ fontSize: "2rem", marginRight: "4px" }} // Adjust the size as needed
            name="person-circle-sharp"
          ></ion-icon>
        ) : (
          <img
            src="" //add image from api
            alt="User Avatar"
            className="w-10 h-10 mr-4 rounded-md"
            onError={handleImageError} // Trigger error handler if image fails to load
          />
        )}
        <div className="w-full max-w-full break-words overflow-hidden">
          <p className="text-md">Abhishek Aji</p>
          <p className="text-xs">abhishek.aji@hungama.com</p>
        </div>
      </div>

      {/* Menu Options */}
      <ul className="space-y-2">
        <li
          className="bg-[#313131] p-2 rounded-md hover:bg-[#414141] cursor-pointer"
          onClick={() => navigate("/dashboard")}
        >
          Dashboard
        </li>
        <li
          className="bg-[#313131] p-2 rounded-md hover:bg-[#414141] cursor-pointer"
          onClick={() => navigate("/account-details")}
        >
          Profile
        </li>
        <li
          className="bg-[#313131] p-2 rounded-md hover:bg-[#414141] cursor-pointer"
          onClick={handleLogOut}
        >
          Logout
        </li>
      </ul>
    </div>
  );
};

export default ProfileDropdown;

import React, { useState } from 'react';

const TermsCheckbox = ({ isChecked, setIsChecked }) => {
    // const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <div className="flex items-center justify-center mt-2">
            <input
                type="checkbox"
                id="termsCheckbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="mr-2 rounded border-[#FF6C2F]-500 checked:bg-[#1A1A1A] checked:border-[#FF6C2F]-500"
                required
            />
            <label htmlFor="termsCheckbox" className="cursor-pointer text-base font-normal break-words">
                I agree to the <b>Terms & Condition</b>
            </label>
        </div>
    );
};

export default TermsCheckbox;

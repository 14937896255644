import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
// import { SearchIcon } from '@heroicons/react/outline';

const Releases = () => {
    const [tdata, setTData] = useState([
        {
            content_name: 'Track title',
            type: 'Audio',
            release_date: '03/02/2024',
            status: 'Approved',
        },
        {
            content_name: 'Track title',
            type: 'Audio',
            release_date: '03/02/2024',
            status: 'Live',
        },
        {
            content_name: 'Track title',
            type: 'Video',
            release_date: '03/02/2024',
            status: 'Draft',
        },
        {
            content_name: 'Track title',
            type: 'Audio',
            release_date: '03/02/2024',
            status: 'Rejected',
        },
        {
            content_name: 'Track title',
            type: 'Video',
            release_date: '03/02/2024',
            status: 'Live',
        }
    ]);

    const [selectedType, setSelectedType] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const navigate = useNavigate();

    const filteredData = tdata.filter(item => {
        if (selectedType !== 'All' && selectedStatus !== 'All') {
            return item.type === selectedType && item.status === selectedStatus;
        } else if (selectedType !== 'All') {
            return item.type === selectedType;
        } else if (selectedStatus !== 'All') {
            return item.status === selectedStatus;
        } else {
            return true;
        }
    });
    const [isOpen, setIsOpen] = useState(false);
    const [statusIsOpen, setStatusIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const statusToggleDropdown = () => {
        setStatusIsOpen(!statusIsOpen);
    }
    const handleNewReleaseClick = () => {
        navigate("/upload");
      };

    return (
        <>
            <div className="px-12 py-20">
                <p className="text-white text-2xl font-medium mb-3">Your <span className="text-[#FF6C2F]">Releases</span></p>
                <div className="flex items-center">
                    <input type="text" placeholder="Search your releases..." className="px-4 py-2 bg-[#D9D9D9] w-96 rounded-l-md focus:outline-none " />
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 p-2 rounded-r-md bg-[#D9D9D9]">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>

                    <button className="flex items-center ml-auto bg-[#FF6C2F] text-black rounded p-2 gap-1"
                    onClick={handleNewReleaseClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        New Release
                    </button>
                </div>

                <table className="table-auto w-full justify-between sm:justify-center mt-20">
                    <thead className="">
                        <tr className="bg-[#E8E8E8] text-black text-left h-11 justify-between">
                            <th className="px-2 rounded-tl-lg rounded-bl-lg">Content Name</th>
                            <th>
                                <button onClick={toggleDropdown} className="flex items-center">
                                    Type<svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
                                </button>

                                {isOpen && (
                                    <div className="absolute mt-3 w-24 text-center bg-[#252525] rounded-md shadow-sm">
                                        <ul className="flex flex-col space-y-2 p-2">
                                            <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                onClick={() => { setSelectedType("All"); toggleDropdown() }}>
                                                All
                                            </li>
                                            <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                onClick={() => { setSelectedType("Audio"); toggleDropdown() }}>Audio</li>
                                            <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                onClick={() => { setSelectedType("Video"); toggleDropdown() }}> Video</li>
                                        </ul>
                                    </div>
                                )}
                            </th>
                            <th>Release Date</th>
                            <th>
                                <button onClick={statusToggleDropdown} className="flex items-center">
                                    Status
                                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7">
                                        </path>
                                    </svg>
                                </button>
                                {statusIsOpen && (
                                    <div className="absolute mt-3 w-32 text-center bg-[#252525] rounded-md shadow-sm">
                                        <ul className="flex flex-col space-y-2 p-2">
                                            <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                onClick={() => { setSelectedStatus("All"); statusToggleDropdown() }}>
                                                All
                                            </li>
                                            <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                onClick={() => { setSelectedStatus("Approved"); statusToggleDropdown() }}>
                                                Approved
                                            </li>
                                            <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                onClick={() => { setSelectedStatus("Disapproved"); statusToggleDropdown() }}>
                                                Disapproved
                                            </li>
                                            <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                onClick={() => { setSelectedStatus("Live"); statusToggleDropdown() }}>
                                                Live
                                            </li>
                                            <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                onClick={() => { setSelectedStatus("Draft"); statusToggleDropdown() }}>
                                                Draft
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </th>
                            <th className="rounded-tr-lg rounded-br-lg">Action</th>
                        </tr>
                    </thead>
                    <tbody className="text-start text-white rounded-bl-lg rounded-br-lg">
                        {filteredData.map((item, index) => (
                            <tr key={index} className="">
                                <td className="px-2 py-3">{item.content_name}</td>
                                <td className="py-3">{item.type}</td>
                                <td className="py-3">{item.release_date}</td>
                                <td className="py-3">
                                    {(item.status === 'Live' || item.status === 'Approved') &&
                                        <div className="flex items-center gap-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#2fd100" className="w-6 h-6">
                                                <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                                            </svg>
                                            {item.status}
                                        </div>
                                    }
                                    {(item.status === 'Draft') &&
                                        <div className="flex items-center gap-2">
                                            <img src="assets\Edit File.svg" alt="edit-icon"></img>
                                            {item.status}
                                        </div>
                                    }
                                    {(item.status === 'Rejected') &&
                                        <div className="flex items-center gap-2">
                                            <img src="assets\Box Important.svg" alt="reject-icon"></img>
                                            {item.status}
                                        </div>
                                    }
                                </td>
                                <td className="py-3">
                                    {(item.status === 'Live' || item.status === 'Approved') &&
                                        <button>
                                            Request
                                        </button>

                                    }
                                    {(item.status === 'Rejected' || item.status === 'Draft') &&
                                        <button>
                                            Edit
                                        </button>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </>
    )
}

export default Releases;
import React, { useRef } from "react";

export default function MarketingPlans() {
  const plansRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      style={{
        backgroundImage: "url('/assets/lines.png')",
        backgroundSize: "100vw",
        backgroundRepeat: "no-repeat",
        fontFamily: "Poppins, sans-serif",
      }}
    >
      <div className="flex">
        <h2
          className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          Marketing <span className="text-[#FEC961] ml-1">Services</span>
        </h2>
      </div>
      <div className="flex flex-col text-[#FFFFFF]">
        <div className="text-[3.5rem] mt-[6%] mb-[3%] ml-[12%] flex items-center justify-left">
          <p className="w-[70%] md:text-[45px] sm:text-[40px] text-[30px]">
            {`Want your music to reach 
          your desired audience?`}
          </p>
        </div>
        <div className="marketing-plansTop flex gap-5 w-[70%] ml-[12%]">
          <div
            className="px-[50px] py-[5px] rounded-md cursor-pointer "
            style={{
              background: "#2f3132",
            }}
            onClick={() => scrollToSection(plansRef)}
          >
            <div className="PlanButtons font-semibold">Free Plan</div>
          </div>
          <div
            className=" px-[50px] py-[5px] rounded-md cursor-pointer"
            style={{
              background: "#4ba9c8",
            }}
            onClick={() => scrollToSection(plansRef)}
          >
            <div className="PlanButtons font-semibold">Silver Plan</div>
          </div>
          <div
            className="px-[50px] py-[5px] rounded-md cursor-pointer"
            style={{
              background: "rgba(255, 193, 71, 0.53)",
            }}
            onClick={() => scrollToSection(plansRef)}
          >
            <div className="PlanButtons font-semibold">Gold Plan</div>
          </div>

          <div
            className="px-[50px] py-[5px] rounded-md cursor-pointer"
            style={{
              background: "#8F00FF33",
            }}
            onClick={() => scrollToSection(plansRef)}
          >
            <div className="PlanButtons font-semibold">Platinum Plan</div>
          </div>
        </div>
        <div className="w-[80%] text-[0.75rem] mt-[3%] ml-[12%] flex items-center justify-center">
          <p>
            {`At Artist Aloud, transparency and simplicity are at the core of what we do. We believe in offering clear and fair pricing without any hidden surprises. Our goal is to provide you with the best value for our services/products while ensuring you understand exactly what you're getting.`}
          </p>
        </div>
        <div className="mt-[15%] mb-[3%] flex flex-col items-end">
          <div className="section2Title w-[40%]">
            <p className="text-[3.5rem] md:text-[45px] sm:text-[40px] text-[30px]">
              {`Promote Your Music on Social Platforms`}
            </p>
            <div className="flex justify-start w-[100%]">
              <div
                className="px-[50px] py-[5px] rounded-md cursor-pointer"
                style={{
                  background: "#8F00FF33",
                }}
                onClick={() => scrollToSection(plansRef)}
              >
                <div className="PlanButtons font-semibold">Platinum Plan</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[15%] mb-[3%] ml-[12%] flex flex-col items-start">
          <div className="w-[55%]">
            <p className="text-[3.5rem] md:text-[45px] sm:text-[40px] text-[30px]">
              {"Get the benefits of Enhanced distribution packages"}
            </p>
            <div className="marketing-plansottom flex justify-start w-[100%] gap-5 sm:flex flex-col">
              <div
                className="px-[50px] py-[5px] rounded-md cursor-pointer"
                style={{
                  background: "#8F00FF33",
                }}
                onClick={() => scrollToSection(plansRef)}
              >
                <div className="PlanButtons font-semibold">Platinum Plan</div>
              </div>
              <div
                className="px-[50px] py-[5px] rounded-md cursor-pointer"
                style={{
                  background: "rgba(255, 193, 71, 0.53)",
                }}
                onClick={() => scrollToSection(plansRef)}
              >
                <div className="PlanButtons font-semibold">Gold Plan</div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex flex-col items-center justify-center rounded-md ml-auto mr-auto w-[90%] mt-[5%] pb-[4%]"
          style={{
            background: `linear-gradient(0deg, #191919, #191919), 
                 linear-gradient(219.73deg, rgba(255, 255, 255, 0.2) -20.78%, rgba(0, 0, 0, 0) 77.31%),
                 linear-gradient(330.82deg, rgba(0, 0, 0, 0.4) -5.18%, rgba(161, 161, 161, 0.1) 92.84%)`,
          }}
          ref={plansRef}
        >
          <div className="mt-6 w-[90%] ml-[2%] mb-6">
            <p className="marketingPlansTitle text-[3.5rem] w-[40%]">
              Select the <span className="text-[#FF6C2F]">plan</span> that fits
              you
            </p>
          </div>
          <div className="flex flex-col items-center w-full">
            <table className="w-[90%] text-sm table-auto border-collapse">
              <thead>
                <tr>
                  <th className="text-left border-b border-gray-500 py-4"></th>
                  <th
                    className="text-center py-8 text-[1rem] rounded-lg"
                    style={{
                      color: "white",
                      border: "4px solid #7f7f7f",
                    }}
                  >
                    <div className="text-[1.5rem] font-bold ">FREE</div>
                  </th>
                  <th
                    className="text-center py-8 text-[1rem] rounded-lg"
                    style={{
                      color: "white",
                      border: "4px solid #add8e6",
                    }}
                  >
                    <div>Silver Plan</div>
                    <div className="text-[2rem] font-bold mt-4">
                      <span className="text-[1.3rem]">₹</span>999
                      <span className="text-[1rem]">/year</span>
                    </div>
                    <div className="text-[0.6rem] font-light opacity-100">
                      + taxes /per song
                    </div>
                  </th>
                  <th
                    className="text-center py-8 text-[1rem] rounded-lg"
                    style={{
                      color: "white",
                      border: "4px solid #ffc147",
                    }}
                  >
                    <div>Gold Plan</div>
                    <div className="text-[2rem] font-bold mt-4">
                      <span className="text-[1.3rem]">₹</span>1499
                      <span className="text-[1rem]">/year</span>
                    </div>
                    <div className="text-[0.6rem] font-light opacity-100">
                      + taxes /per song
                    </div>
                  </th>
                  <th
                    className="text-center py-8 text-[1rem] rounded-lg"
                    style={{
                      color: "white",
                      border: "4px solid #9d46ef",
                    }}
                  >
                    <div>Platinum Plan</div>
                    <div className="text-[2rem] font-bold mt-4">
                      <span className="text-[1.3rem]">₹</span>2499
                      <span className="text-[1rem]">/year</span>
                    </div>
                    <div className="text-[0.6rem] font-light opacity-100">
                      + taxes /per song
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr className="border-b border-gray-500">
                  <td className="text-left py-3 text-[0.8rem]">Releases</td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="text-left py-3 text-[0.8rem]">
                    Social Media Announcements
                  </td>
                  <td className="text-center text-white">1</td>
                  <td className="text-center text-white">2</td>
                  <td className="text-center text-white">4</td>
                  <td className="text-center text-white">4</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="text-left py-3 text-[0.8rem]">
                    Chatbot Engagement
                  </td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="text-left py-3 text-[0.8rem]">
                    Custom Release Date
                  </td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                  <td className="text-center text-green-500">✔</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="text-left py-3 text-[0.8rem]">
                    Revenue Split (Artist Revenue)
                  </td>
                  <td className="text-center text-white text-[0.8rem]">50%</td>
                  <td className="text-center text-white text-[0.8rem]">70%</td>
                  <td className="text-center text-white text-[0.8rem]">75%</td>
                  <td className="text-center text-white text-[0.8rem]">80%</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="text-left py-3 text-[0.8rem]">
                    Customer Service Response Time
                  </td>
                  <td className="text-center text-red-500">✘</td>
                  <td className="text-center text-white text-[0.8rem]">
                    72 hours
                  </td>
                  <td className="text-center text-white text-[0.8rem]">
                    48 hours
                  </td>
                  <td className="text-center text-white text-[0.8rem]">
                    24 hours
                  </td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="text-left py-3 text-[0.8rem]">
                    Playlist Pitching Services
                  </td>
                  <td className="text-center text-red-500">✘</td>
                  <td className="text-center text-red-500">✘</td>
                  <td className="text-center text-red-500">✘</td>
                  <td className="text-center text-green-500">✔</td>
                </tr>
                {/* Button Row (No horizontal line here) */}
                <tr>
                  <td></td>
                  <td className="text-center py-3">
                    <button
                      className="text-white text-[1rem] font-semibold py-2 px-[50px] mx-4 mt-10 rounded-md transition-transform transform hover:scale-105"
                      style={{
                        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), 
            linear-gradient(180deg, #FF6C2F 0%, rgba(255, 160, 47, 0.984375) 0.01%, rgba(255, 100, 0, 0.6) 100%)`,
                        backgroundBlendMode: "overlay",
                      }}
                    >
                      Buy Now
                    </button>
                  </td>
                  <td className="text-center py-3">
                    <button
                      className="text-white text-[1rem] font-semibold py-2 px-[50px] mx-4 mt-10 rounded-md transition-transform transform hover:scale-105"
                      style={{
                        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), 
            linear-gradient(180deg, #FF6C2F 0%, rgba(255, 160, 47, 0.984375) 0.01%, rgba(255, 100, 0, 0.6) 100%)`,
                        backgroundBlendMode: "overlay",
                      }}
                    >
                      Buy Now
                    </button>
                  </td>
                  <td className="text-center py-3">
                    <button
                      className="text-white text-[1rem] font-semibold py-2 px-[50px] mx-4 mt-10 rounded-md transition-transform transform hover:scale-105"
                      style={{
                        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), 
            linear-gradient(180deg, #FF6C2F 0%, rgba(255, 160, 47, 0.984375) 0.01%, rgba(255, 100, 0, 0.6) 100%)`,
                        backgroundBlendMode: "overlay",
                      }}
                    >
                      Buy Now
                    </button>
                  </td>
                  <td className="text-center py-3">
                    <button
                      className="text-white text-[1rem] font-semibold py-2 px-[50px] mx-4 mt-10 rounded-md transition-transform transform hover:scale-105"
                      style={{
                        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), 
            linear-gradient(180deg, #FF6C2F 0%, rgba(255, 160, 47, 0.984375) 0.01%, rgba(255, 100, 0, 0.6) 100%)`,
                        backgroundBlendMode: "overlay",
                      }}
                    >
                      Buy Now
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-[90%] text-[0.9rem] mt-[3%] ml-[5%] flex flex-col mb-[12%]">
          <p>
            {`Please note that all prices listed above are valid for a one-time use only. Subsequent use or additional services may be subject to revised pricing. For further details on multiple-use pricing or extended services, kindly reach out to our customer service team.`}{" "}
            <a
              href="mailto:artistaloud@hungama.com"
              className="underline md:underline-offset-4"
            >
              artistaloud@hungama.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

// import React, { useState } from "react";
// import { publicRouteLink } from "../constant/Routes";
// import Popover from "@mui/material/Popover";
// import Notifications from "./notifications";
// import ProfileDropdown from "./profileDropdown";

// const Navbar2 = () => {
//   let Links = [
//     { name: "Marketing Plans", link: publicRouteLink.MARKETING_PLANS },
//     { name: "Releases", link: publicRouteLink.RELEASES },
//     { name: "Royalty Reports", link: publicRouteLink.ROYALTY_REPORT },
//     { name: "Uploads", link: publicRouteLink.UPLOADS },
//   ];

//   let [open, setOpen] = useState(false);
//   const [anchorElNotification, setAnchorElNotification] = useState(null);
//   const openNotification = Boolean(anchorElNotification);
//   const [anchorElProfile, setAnchorElProfile] = useState(null);
//   const openProfile = Boolean(anchorElProfile);

//   const handleCloseNotification = () => {
//     setAnchorElNotification(null);
//   };

//   // Close Profile Popover
//   const handleProfileClose = () => {
//     setAnchorElProfile(null);
//   };

//   const handleClickNotification = (event) => {
//     setAnchorElNotification(event.currentTarget);
//   };

//   const handleProfileClick = (event) => {
//     setAnchorElProfile(event.currentTarget);
//   };

//   return (
//     <div className="shadow-md w-full sticky top-0 left-0">
//       <div className="md:flex items-center justify-between bg-black text-white py-4 md:px-10 px-7">
//         <div className="font-bold text-2xl cursor-pointer flex items-center font-[Poppins] text-gray-800">
//           <span className=" mr-1 pt-2">
//             <img src="\assets\HungamaLogo.png" alt="hungama-logo" />
//           </span>
//         </div>

//         <div
//           onClick={() => setOpen(!open)}
//           className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
//         >
//           <ion-icon name={open ? "close" : "menu"}></ion-icon>
//         </div>

//         <ul
//           className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-black text-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
//             open ? "top-20 " : "top-[-490px]"
//           }`}
//         >
//           {Links.map((link) => (
//             <li
//               key={link.name}
//               className="md:ml-8 text-[16.67px] font-[500] md:my-0 my-7"
//             >
//               <a href={link.link} className="text-white duration-500">
//                 {link.name}
//               </a>
//             </li>
//           ))}

//           <div className="md:ml-5 w-70 p-1 bg-black text-white overflow-hidden inline-flex justify-center items-center">
//             <div className="">
//               <button onClick={handleClickNotification} className="w-6">
//                 <img src="assets\Doorbell.png" alt="notification-icon"></img>
//                 <Popover
//                   open={openNotification}
//                   anchorEl={anchorElNotification}
//                   onClose={handleCloseNotification}
//                   anchorOrigin={{
//                     vertical: "bottom",
//                     horizontal: "right",
//                   }}
//                   transformOrigin={{
//                     vertical: "top",
//                     horizontal: "right",
//                   }}
//                   sx={{ marginTop: "2%" }}
//                 >
//                   <Notifications />
//                 </Popover>
//               </button>
//             </div>
//           </div>
//           <div className="md:ml-2 p-1 bg-black text-[#FEC961] inline-flex items-center">
//             <button onClick={handleProfileClick} className="w-6">
//               <ion-icon
//                 style={{ fontSize: "1.8rem" }}
//                 name="person-circle-sharp"
//               ></ion-icon>
//             </button>
//             <Popover
//               open={openProfile}
//               anchorEl={anchorElProfile}
//               onClose={handleProfileClose}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "right",
//               }}
//               transformOrigin={{
//                 vertical: "top",
//                 horizontal: "right",
//               }}
//               PopperProps={{
//                 modifiers: [
//                   {
//                     name: "preventOverflow",
//                     options: {
//                       boundary: "window", // Ensure the popover stays within the browser window
//                     },
//                   },
//                   {
//                     name: "flip",
//                     options: {
//                       enabled: true, // Allow the popover to flip if it doesn't fit
//                     },
//                   },
//                   {
//                     name: "offset",
//                     options: {
//                       offset: [0, 10], // Adjust vertical offset, this keeps the popover slightly below the button
//                     },
//                   },
//                   {
//                     name: "computeStyles",
//                     options: {
//                       adaptive: true, // Ensures that popover adjusts based on available space
//                     },
//                   },
//                 ],
//               }}
//               sx={{
//                 "& .MuiPaper-root": {
//                   backgroundColor: "transparent", // Ensure dropdown matches background
//                   boxShadow: "none", // Remove shadow if you want it flat
//                   overflow: "visible", // Ensure no overflow clipping
//                   transform: "translateX(0px)", // Ensure popover stays aligned properly
//                   minWidth: "200px", // Add a minimum width if necessary
//                   maxWidth: "300px", // Limit the maximum width for better control
//                   marginRight: "10px", // Add a small margin to the right to prevent overflow
//                 },
//               }}
//             >
//               <ProfileDropdown />
//             </Popover>
//           </div>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Navbar2;



import React, { useState, useEffect, useRef } from "react";
import { publicRouteLink } from "../constant/Routes";
import Popover from "@mui/material/Popover";
import Notifications from "./notifications"; // Import the Notification component
import ProfileDropdown from "./profileDropdown";

const Navbar2 = () => {
  let Links = [
    { name: "Marketing Plans", link: publicRouteLink.MARKETING_PLANS },
    { name: "Releases", link: publicRouteLink.RELEASES },
    { name: "Royalty Reports", link: publicRouteLink.ROYALTY_REPORT },
    { name: "Uploads", link: publicRouteLink.UPLOADS },
  ];

  // States for controlling popovers
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  let [open, setOpen] = useState(false);

  // Sample notifications data
  const [notifications] = useState([
    "New release available.",
    "Your royalty report is ready.",
    "Marketing plan for Q1 uploaded.",
  ]);

  // Toggle function for notifications popover
  const handleClickNotification = (event) => {
    setAnchorElNotification((prevAnchorEl) => 
      prevAnchorEl ? null : event.currentTarget // Toggle visibility
    );
  };

  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  // Close handlers for the popovers
  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  const openNotification = Boolean(anchorElNotification);
  const openProfile = Boolean(anchorElProfile);

  return (
    <div className="shadow-md w-full sticky top-0 left-0">
      <div className="md:flex items-center justify-between bg-black text-white py-4 md:px-10 px-7">
        <div className="font-bold text-2xl cursor-pointer flex items-center font-[Poppins] text-gray-800">
          <span className="mr-1 pt-2">
            <img src="/assets/HungamaLogo.png" alt="hungama-logo" />
          </span>
        </div>

        {/* Hamburger menu button for mobile */}
        <div
          onClick={() => setOpen((prevOpen) => !prevOpen)}
          className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
        >
          <ion-icon name={open ? "close" : "menu"}></ion-icon>
        </div>

        {/* Navbar links */}
        <ul
          className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-black text-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-20" : "top-[-490px]"
          }`}
        >
          {Links.map((link) => (
            <li key={link.name} className="md:ml-8 text-[16.67px] font-[500] md:my-0 my-7">
              <a href={link.link} className="text-white duration-500">
                {link.name}
              </a>
            </li>
          ))}

          {/* Notification bell */}
          <div className="md:ml-5 w-70 p-1 bg-black text-white overflow-hidden inline-flex justify-center items-center">
            <div>
              <button onClick={handleClickNotification} className="w-6">
                <img src="assets/Doorbell.png" alt="notification-icon" />
                <Popover
                  open={openNotification}
                  anchorEl={anchorElNotification}
                  onClose={handleCloseNotification}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{ marginTop: "2%" }}
                >
                  <Notifications notifications={notifications} />
                </Popover>
              </button>
            </div>
          </div>

          {/* Profile dropdown */}
          <div className="md:ml-2 p-1 bg-black text-[#FEC961] inline-flex items-center">
            <button onClick={handleClickProfile} className="w-6">
              <ion-icon style={{ fontSize: "2.2rem" }} name="person-circle-sharp"></ion-icon>
            </button>
            <Popover
              open={openProfile}
              anchorEl={anchorElProfile}
              onClose={handleCloseProfile}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PopperProps={{
                modifiers: [
                  {
                    name: "preventOverflow",
                    options: {
                      boundary: "window", // Ensure the popover stays within the window
                    },
                  },
                  {
                    name: "flip",
                    options: {
                      enabled: true,
                    },
                  },
                  {
                    name: "offset",
                    options: {
                      offset: [0, 10], // Adjust vertical offset
                    },
                  },
                  {
                    name: "computeStyles",
                    options: {
                      adaptive: true,
                    },
                  },
                ],
              }}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "transparent",
                  boxShadow: "none", // Remove shadow for flat dropdown
                  overflow: "visible", // Prevent overflow clipping
                  minWidth: "200px",
                  maxWidth: "300px",
                  marginRight: "10px", // Prevent overflow to the right
                },
              }}
            >
              <ProfileDropdown />
            </Popover>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Navbar2;

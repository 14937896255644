import React from "react";

const ContactUs = () => {
  return (
    <>
      <div className="w-full bg-[#1A1A1A] py-16 px-20">
        <h1 className="pb-16 text-[33px] text-white font-medium">
          Contact <span className="text-[#FEC961]">Us</span>
        </h1>
        <div className="flex">
          <div className="w-3/5 flex items-center px-20 bg-[#00000040] rounded-lg">
            <div className="text-base text-white font-medium">
              <div className="pb-12">
                <h2 className=" text-[#FF6C2F]">Connect with us</h2>
                <div className="flex flex-row space-x-4 pt-6 ">
                  <a
                    href="https://www.instagram.com/artistaloud/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="assets\instagram.png" alt="insta-logo" />
                  </a>
                  <a
                    href="https://www.facebook.com/artistaloud/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="assets\facebook.png" alt="fb-logo" />
                  </a>

                  <a
                    href="https://www.youtube.com/channel/UCPXM1s5WGdX_mFJzxzuw7eQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="assets\youtube.png" alt="utube-logo" />
                  </a>
                  <a
                    href="https://x.com/AloudArtist"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="assets\Vector.svg" alt="twitter-logo" />
                  </a>
                </div>
              </div>
              {/* <p className="text-[#FF6C2F] ">Office Address</p>
                            <p className="pt-5 break-words">Artist Aloud<br />Address: SF-B-07, Art Guild House, Phoenix Market City,Kurla West, Lal Bahadur Shastri Marg,Kurla West, Mumbai, Maharashtra <br /> 400070</p> */}
              <p className="pt-6 text-[#FF6C2F] ">Contact Info</p>
              <p className="pt-5 font-normal break-words">
                Artist Aloud
                {/* <br />0<span className="underline">22 7165 3344</span> */}
                <br />{" "}
                <span className="underline">artistaloud@hungama.com</span>
              </p>
            </div>
          </div>
          <div className="w-2/5 h-[500px] relative transition">
            <img
              src="assets\contactUs-bg.png"
              alt=""
              className="w-full h-full bg-[linear-gradient(0deg, black 13%, rgba(26.56, 25.79, 25.79, 0) 53%)]"
            />
            <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center">
              <img
                src="assets\logo-img.png"
                alt=""
                className="w-4/5 h-4/5 object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;

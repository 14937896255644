import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { publicRouteLink } from "../constant/Routes";

const Dashboard = () => {
  const [isNewUser, setIsNewUser] = useState(false);
  const [tdata, setTData] = useState([
    {
      content_name: "Track title",
      type: "Audio",
      release_date: "03/02/2024",
      status: "Approved",
    },
    {
      content_name: "Track title",
      type: "Audio",
      release_date: "03/02/2024",
      status: "Live",
    },
    {
      content_name: "Track title",
      type: "Video",
      release_date: "03/02/2024",
      status: "Draft",
    },
    {
      content_name: "Track title",
      type: "Audio",
      release_date: "03/02/2024",
      status: "Rejected",
    },
    {
      content_name: "Track title",
      type: "Video",
      release_date: "03/02/2024",
      status: "Live",
    },
  ]);
  const [selectedType, setSelectedType] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");

  const navigate = useNavigate();

  const filteredData = tdata.filter((item) => {
    if (selectedType !== "All" && selectedStatus !== "All") {
      return item.type === selectedType && item.status === selectedStatus;
    } else if (selectedType !== "All") {
      return item.type === selectedType;
    } else if (selectedStatus !== "All") {
      return item.status === selectedStatus;
    } else {
      return true;
    }
  });
  const [isOpen, setIsOpen] = useState(false);
  const [statusIsOpen, setStatusIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const statusToggleDropdown = () => {
    setStatusIsOpen(!statusIsOpen);
  };

  const handleNewReleaseClick = () => {
    navigate("/upload");
  };

  return (
    <>
      <div
        className="flex flex-col justify-center w-full pb-20 align-center"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        <div className="flex flex-col md:flex-row pt-10 md:pt-20 md:px-20 gap-10 items-end md:items-start">
          <div className="w-full md:w-1/5">
            <p className="text-white text-2xl font-medium mb-2 ">
              Hello {"user"}
            </p>
            <div className=" bg-[#FEC961] p-2 rounded h-20 w-60">
              <p className="text-xs mb-4 font-semibold">Total Earnings</p>
              <p className="text-3xl font-medium">
                {"1254.75"}
                <span className="text-xs align-super">INR</span>
              </p>
            </div>
          </div>

          <div
            className="rounded px-4 h-20 flex items-center w-[100%] md:w-4/5 md:mt-auto md:ml-5"
            style={{
              background:
                "linear-gradient(90deg, #FF6C2F 38.37%, #FEC961 135.09%)",
            }}
          >
            <p className="text-2xl font-medium gap-y-14">
              Create your first release now!
            </p>
            <button
              className="flex items-center ml-auto bg-white text-black rounded p-2 text-sm"
              onClick={handleNewReleaseClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              New Release
            </button>
          </div>
        </div>

        <div className="container">
          {isNewUser && (
            <div className="text-white w-full ml-20 ">
              <div className="font-medium text-2xl mt-20 mb-1">
                Your Releases
              </div>
              <div className="pb-20">
                <div
                  className=" h-72 mt-2.5 rounded flex items-center justify-center"
                  style={{ backgroundColor: "rgba(37, 37, 37, 1)" }}
                >
                  {/* Child div containing SVG icon */}
                  <div className="flex flex-col items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#FF6C2F" // Color of the icon
                      className="w-8 h-8 mb-5" // Margin below the icon
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                    {/* Text below the icon */}
                    <p className="text-center opacity-20">
                      No releases yet, create now
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!isNewUser && (
            <div className="text-white w-full md:pl-20 px-2">
              <div className="flex mt-20 w-full justify-between items-center">
                <div className="font-medium text-2xl mb-1">Your Releases</div>
                <Link to={publicRouteLink.RELEASES}>
                  <button className="border-none text-right">View All</button>
                </Link>
              </div>

              <table className="table-auto w-full justify-between sm:justify-center text-[0.9rem]">
                <thead className="">
                  <tr className="bg-[#E8E8E8] text-black text-left h-11">
                    <th className="px-4 rounded-tl-md rounded-bl-md w-1/3">
                      Content Name
                    </th>
                    <th className="w-1/4">
                      <button
                        onClick={toggleDropdown}
                        className="flex items-center"
                      >
                        Type
                        <svg
                          className="w-4 h-4 ml-1"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          ></path>
                        </svg>
                      </button>
                      {isOpen && (
                        <div className="absolute mt-3 w-24 text-center bg-[#252525] rounded-md shadow-sm">
                          <ul className="flex flex-col space-y-2 p-2">
                            <li
                              className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                              onClick={() => {
                                setSelectedType("All");
                                toggleDropdown();
                              }}
                            >
                              All
                            </li>
                            <li
                              className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                              onClick={() => {
                                setSelectedType("Audio");
                                toggleDropdown();
                              }}
                            >
                              Audio
                            </li>
                            <li
                              className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                              onClick={() => {
                                setSelectedType("Video");
                                toggleDropdown();
                              }}
                            >
                              Video
                            </li>
                          </ul>
                        </div>
                      )}
                    </th>
                    <th className="w-1/4">Release Date</th>
                    <th className="w-1/4">
                      <button
                        onClick={statusToggleDropdown}
                        className="flex items-center"
                      >
                        Status
                        <svg
                          className="w-4 h-4 ml-1"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          ></path>
                        </svg>
                      </button>
                      {statusIsOpen && (
                        <div className="absolute mt-3 w-32 text-center bg-[#252525] rounded-md shadow-sm">
                          <ul className="flex flex-col space-y-2 p-2">
                            <li
                              className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                              onClick={() => {
                                setSelectedStatus("All");
                                statusToggleDropdown();
                              }}
                            >
                              All
                            </li>
                            <li
                              className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                              onClick={() => {
                                setSelectedStatus("Approved");
                                statusToggleDropdown();
                              }}
                            >
                              Approved
                            </li>
                            <li
                              className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                              onClick={() => {
                                setSelectedStatus("Rejected");
                                statusToggleDropdown();
                              }}
                            >
                              Rejected
                            </li>
                            <li
                              className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                              onClick={() => {
                                setSelectedStatus("Live");
                                statusToggleDropdown();
                              }}
                            >
                              Live
                            </li>
                            <li
                              className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                              onClick={() => {
                                setSelectedStatus("Draft");
                                statusToggleDropdown();
                              }}
                            >
                              Draft
                            </li>
                          </ul>
                        </div>
                      )}
                    </th>
                    <th className="rounded-tr-md rounded-br-md w-1/4 pr-4">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="text-start rounded-bl-lg rounded-br-lg text-[0.8rem]">
                  {filteredData.map((item, index) => (
                    <tr key={index} className=" rounded-lg">
                      <td className="px-4 py-3">{item.content_name}</td>
                      <td className="py-3">{item.type}</td>
                      <td className="py-3">{item.release_date}</td>
                      <td className="py-3">
                        {(item.status === "Live" ||
                          item.status === "Approved") && (
                          <div className="flex items-center gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="#2fd100"
                              className="w-6 h-6"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            {item.status}
                          </div>
                        )}
                        {item.status === "Draft" && (
                          <div className="flex items-center gap-2">
                            <img src="assets\Edit File.svg" alt="edit-icon" />
                            {item.status}
                          </div>
                        )}
                        {item.status === "Rejected" && (
                          <div className="flex items-center gap-2">
                            <img
                              src="assets\Box Important.svg"
                              alt="reject-icon"
                            />
                            {item.status}
                          </div>
                        )}
                      </td>
                      <td className="py-3 pr-4">
                        {(item.status === "Live" ||
                          item.status === "Approved") && (
                          <button>Request</button>
                        )}
                        {(item.status === "Rejected" ||
                          item.status === "Draft") && <button>Edit</button>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;

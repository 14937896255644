import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { publicRouteLink } from "../constant/Routes";
import axios from "axios";
import { toast } from "react-hot-toast";
import { MainContext } from "../context/main";
import { useNavigate } from "react-router-dom";

const OtpVerification = ({ onClose }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [isValid, setIsValid] = useState(true);
  const { countryCode, mob, buttonValue, setMob, setCountryCode } =
    useContext(MainContext);
  const [disabled, setDisabled] = useState(false);
  const [timer, setTimer] = useState(60);
  const navigate = useNavigate();

  useEffect(() => {
    if (!mob || !countryCode) {
      toast.error("Unauthorized access. Redirecting...");
      navigate(publicRouteLink.SIGNIN); // Redirect to SignIn page
    }
  }, [mob, countryCode, navigate]);

  useEffect(() => {
    return () => {
      setMob(null);
      setCountryCode(null);
    };
  }, [setMob, setCountryCode]);

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setDisabled(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleOtpChange = (index, value) => {
    if (isNaN(value)) {
      return;
    }
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < 3) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const verifyOTP = async () => {
    // Validate OTP input
    if (otp.some((digit) => digit.trim() === "")) {
      toast.error("Please fill all the OTP fields.");
      return;
    }

    try {
      const resp = await axios({
        url: "https://haartistaloud-backend.kollywoodhungama.com/v2/verify-otp",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        data: {
          mob: mob,
          otp: otp.join(""),
          countryCode: "+" + countryCode,
        },
      });

      console.log("Full API response:", resp);

      if (resp.data.result?.statusCode === 200) {
        sessionStorage.setItem("Token", resp.data.token);
        console.log(sessionStorage.getItem("Token"));
        toast.success("OTP Verified!");
        setIsValid(true); // Ensure error message is cleared
        if (buttonValue === "SignIn") {
          navigate(publicRouteLink.DASHBOARD);
        } else {
          navigate(publicRouteLink.COMPLETE_YOUR_ACCOUNT);
        }
      } else {
        setIsValid(false);
        toast.error(resp.data.message || "OTP verification failed.");
      }
    } catch (error) {
      console.log("Error from verifyOTP function:", error.response || error);
      toast.error("Failed to verify OTP. Please try again.");
    }
  };

  const sendOTP = async () => {
    try {
      const resp = await axios({
        url: "https://haartistaloud-backend.kollywoodhungama.com/v2/send-otp",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        data: {
          mob: mob,
          countryCode: "+" + countryCode,
        },
      });

      if (resp.data?.statusCode === 200) {
        toast.success("OTP sent successfully!");
        setTimer(60);
        setDisabled(true);
      } else {
        toast.error(
          resp.data?.message || "Failed to send OTP. Please try again!"
        );
      }
    } catch (error) {
      console.log("Error from sendOTP function:", error.response || error);
      toast.error("Error sending OTP. Please try again later.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex bg-white rounded-[4%] mt-[7%]">
        <div className="w-80 mt-9 ml-10">
          <h2 className="text-3xl font-semibold mb-3 text-[#FF6C2F]">
            Enter verification code
          </h2>
          <p className="text-[#181818] text-sm font-medium mb-7 break-words">
            We have just sent a verification code to +{countryCode}
            {mob}{" "}
          </p>
          <div className="flex mb-7">
            {otp.map((digit, index) => (
              <input
                key={index}
                id={`otp-input-${index}`}
                className="w-9 h-12 text-center bg-[#E6E6E6] text-[#FF6C2F] text-xl font-medium border border-black border-solid rounded-md mx-2"
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
              />
            ))}
          </div>

          {!isValid && (
            <p className="text-[#FA3235]">
              The security code is invalid or expired.
            </p>
          )}

          <div className="flex items-center">
            <button
              onClick={sendOTP}
              disabled={disabled}
              className={`text-[#2EB4FF] text-sm font-normal px-2 mb-7 ${
                disabled ? "cursor-not-allowed" : "hover:bg-white"
              }`}
            >
              Send the code again
            </button>
            {disabled && (
              <span style={{ color: "#FF6C2F" }}> (00:{timer}) </span>
            )}
          </div>
          <Link to={publicRouteLink.CHANGE_PHONENUMBER}>
            <button className="text-[#2EB4FF] text-sm font-normal px-2 mb-7">
              Change phone number
            </button>
          </Link>

          <br />
          <button
            onClick={verifyOTP}
            className="bg-[#FF6C2F] text-white text-sm font-normal px-4 py-2 mb-12 rounded-md w-80 h-12 "
          >
            Verify
          </button>
        </div>

        <div className="ml-16">
          <img
            src="assets/verification-pic.png"
            alt="verification-pic"
            className="h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NumberPopup = ({ onClose }) => {
  const [showOtpField, setShowOtpField] = useState(false);
  const [mob, setMob] = useState("");
  const [countryCode, setCountryCode] = useState(""); // Fetched from user API
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Fetch user details to get country code
  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = sessionStorage.getItem('Token');
      try {
        const response = await axios.get(
          `https://haartistaloud-backend.kollywoodhungama.com/v1/userById/${token}`
        );
        const { country_code } = response.data; // Adjust key if needed based on API response
        setCountryCode(country_code || "+91"); // Default to +91 if not found
      } catch (error) {
        console.error("Error fetching user details:", error);
        setErrorMessage("Failed to fetch user details.");
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handlePhoneNumberChange = (value) => {
    if (/^\d*$/.test(value)) {
      setMob(value);
    }
  };

  const handleOtpChange = (value) => {
    if (/^\d*$/.test(value) && value.length <= 4) {
      setOtp(value);
    }
  };

  const handleSendOtpClick = async () => {
    if (!mob) {
      setErrorMessage("Please enter a phone number.");
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://haartistaloud-backend.kollywoodhungama.com/v2/send-otp",
        {
          mob,
          countryCode,
        }
      );
      console.log("OTP sent:", response.data);
      setShowOtpField(true);
      setTimer(59); // Start timer
      toast.success("OTP sent successfully!");
    } catch (error) {
      console.error("Error sending OTP:", error);
      setErrorMessage("Failed to send OTP.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOtpClick = async () => {
    console.log("Verify button clicked!");

    if (!otp) {
      console.log("No OTP entered.");
      setErrorMessage("Please enter the OTP.");
      return;
    }

    try {
      console.log("Verifying OTP...");
      setIsLoading(true);

      const verifyResponse = await axios.post(
        "https://haartistaloud-backend.kollywoodhungama.com/v2/verify-otp",
        {
          mob,
          otp,
          countryCode,
        }
      );

      console.log("OTP verified response:", verifyResponse.data);

      if (verifyResponse.data?.message === "OTP verified successfully") {
        console.log("OTP verified successfully! Updating number...");
        const updateResponse = await axios.patch(
          `https://haartistaloud-backend.kollywoodhungama.com/v3/updateContactNo/400f6036b07146eab9d2eab150fa5f`, // Replace with correct userId if necessary
          {
            contactNo: mob, // Ensure phoneNumber is valid
          }
        );

        console.log("Number update response:", updateResponse.data);

        if (updateResponse.status === 200) {
          sessionStorage.setItem('Token', verifyResponse.data.token);
          console.log("Number update successful!");
          toast.success("Number updated successfully!");
          onClose(); // Close the popup
        } else {
          setErrorMessage("Failed to update phone number.");
        }
      }
    } catch (error) {
      console.error("Error during OTP verification or number update:", error);
      setErrorMessage(
        "An error occurred while verifying the OTP or updating the number."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content" style={{ width: "30%" }}>
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        {!showOtpField ? (
          <>
            <div>
              <h1
                style={{
                  fontWeight: "400",
                  fontSize: "25px",
                  marginBottom: "2%",
                }}
              >
                Enter Phone Number
              </h1>
            </div>
            <input
              type="text"
              value={mob}
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
              className="rounded-[5px] bg-[#4c4c4c] w-[auto] h-[2.7rem] mt-[0%] text-[#FFFFFF] font-[Poppins]"
              maxLength="10"
            />
            <div className="w-full h-4">
              {errorMessage && (
                <p style={{ color: "red", textAlign: "center" }}>
                  {errorMessage}
                </p>
              )}
            </div>
            <button
              className="button-popup"
              onClick={handleSendOtpClick}
              disabled={isLoading}
              style={{
                marginTop: "7%",
                marginBottom: "4%",
                padding: "8px 16px",
                border: "none",
                width: "28%",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "13px",
                backgroundColor: "#ff6c2f",
                color: "white",
                marginLeft: "35%",
              }}
            >
              {timer > 0 ? `Resend OTP in ${timer}s` : "Send OTP"}
            </button>
          </>
        ) : (
          <>
            <h1
              style={{
                fontWeight: "400",
                fontSize: "25px",
                marginBottom: "2%",
              }}
            >
              Enter OTP
            </h1>
            <input
              type="text"
              value={otp}
              onChange={(e) => handleOtpChange(e.target.value)}
              className="rounded-[5px] bg-[#4c4c4c] w-[auto] h-[2.7rem] mt-[0%] text-[#FFFFFF] font-[Poppins]"
              maxLength="4"
            />
            <button
              className="button-popup"
              onClick={handleVerifyOtpClick}
              style={{
                marginTop: "5%",
                marginBottom: "5%",
                padding: "8px 16px",
                border: "none",
                width: "20%",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "13px",
                backgroundColor: "#ff6c2f",
                color: "white",
                marginLeft: "40%",
              }}
            >
              Verify
            </button>

            {timer > 0 ? (
              <p
                className="change-number"
                onClick={() => setTimer(59)}
                style={{
                  fontSize: "13px",
                  textAlign: "center",
                  marginBottom: "2%",
                }}
              >
                <p>Resend OTP in {timer}s</p>
              </p>
            ) : (
              <p
                className="change-number"
                onClick={handleSendOtpClick}
                style={{
                  fontSize: "13px",
                  textAlign: "center",
                  textDecorationLine: "underline",
                  marginBottom: "3%",
                  cursor: "pointer",
                }}
              >
                Send code again
              </p>
            )}
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default NumberPopup;

import React, { useState, useEffect } from "react";
import { publicRouteLink } from "../constant/Routes";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useContext } from "react";
import { MainContext } from "../context/main";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

const SignInForm = ({ onClose }) => {
  const [ph, setPh] = useState("");
  const [isPhoneExists, setIsPhoneExists] = useState(true); // Assume phone exists by default
  const { countryCode, setCountryCode, mob, setMob, updateButtonInfo } =
    useContext(MainContext);
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState("");
  const navigate = useNavigate();

  const handleClick = async (event) => {
    await checkPhoneNumber();  // Check phone number first
  };

  // Function to check if phone number exists in the database
  const checkPhoneNumber = async () => {
    try {
      const response = await fetch(
        `https://haartistaloud-backend.kollywoodhungama.com/v1/CheckPhoneNo/${mob}`
      );
      const respData = await response.json();

      // If the phone number exists (status 200), proceed with OTP
      if (respData.status === 409) {
        await sendOTP();
      }
      // If the phone number does not exist (status 200 but not registered), show sign up message
      else if (respData.status === 200) {
        setIsPhoneExists(false);  // Set flag for no account
        toast.error("Phone number not registered. Please Sign Up!");
      }
    } catch (err) {
      toast.error("Something went wrong. Please try again!");
      console.error("Error from checkPhoneNumber function:", err.message);
    }
  };

  // Handle phone number change from PhoneInput component
  const handlePhChange = (phone, country) => {
    let newPh = phone.split(country.dialCode);
    newPh = newPh[1];
    setPh(phone);
    setMob(newPh);  // Update mobile number in context
    setCountryCode(country.dialCode);  // Set country code
  };

  // Send OTP after checking phone number
  const sendOTP = async () => {
    onClose && onClose();
    updateButtonInfo("Send OTP", "SignIn");

    try {
      const url =
        "https://haartistaloud-backend.kollywoodhungama.com/v2/send-otp";

      const resp = await axios({
        url: url,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        data: {
          mob: mob,
          countryCode: "+" + countryCode,
        },
      });

      // Log the response for debugging purposes
      console.log("OTP Response:", JSON.stringify(resp.data, null, 2));

      // Check if the response indicates success
      if (resp.data.message === "OTP Sent Successfully") {
        toast.success("OTP sent successfully!");
        navigate(publicRouteLink.OTP_VERIFICATION);  // Redirect to OTP verification page
      } else {
        toast.error(
          `OTP not sent. Error: ${resp.data.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.log("Error from sendOTP function:", error);
      toast.error(`OTP request failed: ${error.message || "Unknown error"}`);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      navigate(publicRouteLink.DASHBOARD);
      console.log(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    console.log(process.env);
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  return (
    <div className="flex items-center justify-center bg-[#1A1A1A] px-16 py-12">
      <div className="bg-[#1A1A1A] text-white rounded-md w-96">
        <h1 className="text-3xl font-bold text-center">
          Sign in to your account
        </h1>
        <div className="pt-5">
          <label
            className="block text-white text-sm font-medium"
            htmlFor="phoneNumber"
          >
            Mobile Number
          </label>
          <div className="flex items-center">
            <PhoneInput
              country={"in"}
              value={ph}
              onChange={handlePhChange}
              inputClass="w-full pl-12 text-black"
              buttonClass="absolute"
              containerClass="relative w-full"
            />
          </div>
        </div>
        <button
          className="w-full bg-[#FF6C2F] text-white h-12 rounded-md text-sm font-medium mt-3"
          type="submit"
          onClick={handleClick}
        >
          Send OTP
        </button>

        <div className="my-3 text-center">or</div>
        <button
          className="w-full bg-[#262626] text-white h-12 rounded-md text-sm font-medium mt-3"
          type="button"
        >
          <span className="flex flex-row justify-center items-center">
            <img
              src="assets/google-icon.svg"
              alt="google-icon"
              className="pr-2"
            />
            Sign in with Google
          </span>
        </button>

        {!isPhoneExists && (
          <p className="text-[#FA3235]">
            You don't have an account. Please create an account!
          </p>
        )}

        <div className="flex flex-row items-center justify-end mt-10 space-x-4">
          <p>Don’t have an account?</p>
          <a
            className="bg-[#262626] text-[#FF6C2F] w-28 h-9 flex items-center justify-center ml-3 rounded-md"
            href={publicRouteLink.SIGNUP}
          >
            Create Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignInForm;
